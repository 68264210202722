import PageContent from "../../layout/PageContent";
import {TrackingViewList} from "../lists/TrackingViewList";
import * as React from "react";
import {useBrandItemTrackingShopsQuery} from "../../Queries";
import {LinearProgress} from "@mui/material";

type BrandItemTrackingProps = {
   brandItemId: string
}

export const BrandItemTracking = ({brandItemId}: BrandItemTrackingProps) => {
   const {data} = useBrandItemTrackingShopsQuery({
      variables: {
         brandItemId
      }
   });
   if (!data) {
      return <LinearProgress/>
   }

   const stockItemIds = (data.BrandItem?.shops || []).map(shop => shop!.stockItemId);

   if (stockItemIds.length === 0) {
      return <PageContent>
         <div>Not published to any storefront</div>
      </PageContent>
   }

   return <PageContent>
      <TrackingViewList filter={{
         _operators: {
            objectId: {
               in: stockItemIds
            }
         }
      }} agentDetails={true} brandColumn={true}/>
   </PageContent>
}