import React from 'react';
import {InvoiceLine, useInvoiceLineStockItemQuery} from "../../../Queries";
import {Box, Card, CardContent, LinearProgress} from "@mui/material";
import {Thumbnails} from "../../../components/Thumbnails";
import {BodyText, TitleText} from "../../../layout/Typography";
import dayjs from "dayjs";
import {LinkTo} from "../../../components/LinkTo";
import {BrandItemPagePath} from "../../brand/BrandItemPage";

export const InvoiceLineStockItem = ({invoiceLine, stockItemId}: {
   invoiceLine: Partial<InvoiceLine>,
   stockItemId: string
}) => {
   const {data} = useInvoiceLineStockItemQuery({
      variables: {stockItemId}
   });

   if (!data) {
      return <LinearProgress/>;
   }

   const stockItem = data.StockItemById?.resolved;
   const brandItem = data.BrandItem?.resolved;

   return (
      <Card sx={{display: 'flex', minWidth: '600px'}}>
         <Box sx={{width: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Thumbnails hideBorder
                        imageUrls={stockItem?.imageUrls || brandItem?.imageUrls || []}
                        size={100}
                        maxImages={1}
            />
         </Box>
         <CardContent sx={{flex: '1 0 auto'}}>
            <TitleText type="h2">
               Item Number: <LinkTo
               to={BrandItemPagePath(invoiceLine.brandHouseId, data?.BrandItem?._id, false)}>{stockItem?.itemNr || brandItem?.itemNr || 'N/A'}</LinkTo>
            </TitleText>
            <BodyText type={"body1"} sx={{fontWeight: 'bold', marginBottom: '5px'}}>
               {stockItem?.title || brandItem?.nameLocalized?.[0]?.value || 'No Title Available'}
            </BodyText>
            <BodyText type={"body1"}>
               Cost: {invoiceLine.cost || 'N/A'} {invoiceLine.currency}
            </BodyText>
            <BodyText type="subtitle2">
               {/* Replace with actual date when available */}
               Added to
               invoice {dayjs(invoiceLine.lineDate).format("DD. MMMM YYYY")} (Created {dayjs(brandItem?.createdAt || stockItem?.createdAt).format('DD-MM-YYYY')})
            </BodyText>
         </CardContent>
      </Card>
   );
};