import {Dialog, LinearProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useBrandItemProductImagesQuery} from "../../../Queries";

type BrandItemImportDialogProps = {
   brandItemId: string
   onSelect: (data: OriginalImageData) => void
   onHide: () => void
}

export const BrandItemImportDialog = ({brandItemId, onHide, onSelect}: BrandItemImportDialogProps) => {
   return <Dialog open={true} onClose={onHide} fullWidth={true} maxWidth={"lg"}>
      <OriginalImages
         brandItemId={brandItemId}
         onSelect={onSelect}
      />
   </Dialog>
}

type OriginalImageData = {
   imageId: string
   imageUrl: string
}

type OriginalImagesProps = {
   brandItemId?: string
   onSelect: (data: OriginalImageData) => void
}

const OriginalImages = ({brandItemId, onSelect}: OriginalImagesProps) => {
   const classes = useStyles();
   const {data} = useBrandItemProductImagesQuery({
      variables: {
         brandItemId: brandItemId
      }
   })

   if (!data) {
      return <LinearProgress/>
   }

   const images = data?.BrandItemMasterImages || []
   return <div className={classes.container}>
      {images.length === 0 && <div>No images found</div>}
      {images.map(img => {
         return <div
            key={'import_image' + img!._id}
            onClick={() => onSelect({
               imageId: img!._id,
               imageUrl: img!.imageUrl!
            })}
            className={classes.image}
            style={{
               backgroundImage: 'url(' + img!.imageUrl + ')',
               backgroundRepeat: "no-repeat",
               backgroundSize: "contain",
               backgroundPosition: "center",
               height: '380px', width: '380px',
               cursor: 'pointer'
            }}
         >
         </div>
      })
      }
   </div>

}

const useStyles = makeStyles({
   container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px'
   },
   image: {
      position: 'relative',
      color: 'transparent',
      "&:hover *": {
         color: 'white',
         display: 'flex',
         flexDirection: 'column'
      }
   },
   imageText: {
      fontSize: '11px',
      position: 'absolute',
      bottom: 0,
      left: '20px',
      right: '20px',
      display: 'none',
      backgroundColor: '#000000',
      opacity: '0.5',
      padding: '5px 10px 5px 10px',
      minHeight: '40px'
   }
})