import * as React from "react";
import {useCallback, useMemo} from "react";
import {
   EnumBrandItemItemState,
   FilterFindManyBrandItemInput,
   FlexBrandItemFragment,
   SortFindManyBrandItemInput,
   useBrandItemsBrandSettingsQuery,
   useBrandItemsListManyLazyQuery
} from "../../Queries";
import {FlexCommand, FlexFilter, FlexListColumn, FlexSort, FlexTab} from "../../components/flexlist/FlexTypes";
import {BrandItemColumnsProps, getBrandItemColumns} from "./BrandItemsListColumns";
import {FlexList} from "../../components/flexlist/FlexList";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../atoms/CurrentUser";


type HeaderOptionsProps = {
   hide?: boolean
}

type BrandItemsListProps = {
   listId?: string
   brandHouseId?: string
   currency?: string
   headerOptions?: HeaderOptionsProps
   columnOptions?: BrandItemColumnsProps
   onSelectItem?: (brandItem: FlexBrandItemFragment, newWindow?: boolean) => void
   commands?: FlexCommand<FlexBrandItemFragment>[]
   filter?: any
}

const PAGE_SIZE = 20;

export const BrandItemsList = ({
                                  listId,
                                  brandHouseId,
                                  columnOptions,
                                  onSelectItem,
                                  commands,
                                  headerOptions,
                                  filter: extraFilter
                               }: BrandItemsListProps) => {
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   const {data} = useBrandItemsBrandSettingsQuery({
      variables: {
         brandHouseId
      }
   })
   const [fetchMore] = useBrandItemsListManyLazyQuery({
      fetchPolicy: "no-cache"
   });

   const columns: FlexListColumn<FlexBrandItemFragment>[] = useMemo(
      () => getBrandItemColumns(columnOptions || {}), [columnOptions]
   )

   const sortSpec: FlexSort<SortFindManyBrandItemInput>[] = [{
      id: 'LocationAsc',
      label: 'Location A-Z',
      value: SortFindManyBrandItemInput.STOCKLOCATION_ASC
   }, {
      id: 'LocationDesc',
      label: 'Location Z-A',
      value: SortFindManyBrandItemInput.STOCKLOCATION_DESC
   }, {
      id: 'CreatedAsc',
      label: 'Created (oldest first)',
      value: SortFindManyBrandItemInput._ID_ASC
   }, {
      id: 'CreatedDesc',
      label: 'Created (newest first)',
      value: SortFindManyBrandItemInput._ID_DESC
   }, {
      id: 'UpdatedAsc',
      label: 'Updated (oldest first)',
      value: SortFindManyBrandItemInput.UPDATEDAT_ASC
   }, {
      id: 'UpdatedDesc',
      label: 'Updated (newest first)',
      value: SortFindManyBrandItemInput.UPDATEDAT_DESC
   }, {
      id: 'LayawayDesc',
      label: 'Layaway',
      value: SortFindManyBrandItemInput.STOCKLOCATIONTS_DESC
   }]

   const filterSpec: FlexFilter<FilterFindManyBrandItemInput>[] = useMemo(() => {
      const spec: FlexFilter<FilterFindManyBrandItemInput>[] = [{
         id: 'status',
         label: 'Product state',
         options: [{
            label: 'None',
            filter: {
               itemState: EnumBrandItemItemState.NONE
            }
         }, {
            label: 'Draft',
            filter: {
               itemState: EnumBrandItemItemState.DRAFT
            }
         }, {
            label: 'Review',
            filter: {
               itemState: EnumBrandItemItemState.REVIEW
            }
         }, {
            label: 'Ready',
            filter: {
               itemState: EnumBrandItemItemState.READY
            }
         }]
      }, {
         id: 'problems',
         label: 'Problems',
         options: [{
            label: 'Problems (Yes)',
            filter: {
               hasProblems: true
            }
         }, {
            label: 'Problems (No)',
            filter: {
               hasProblems: false
            }
         }]
      }, {
         id: 'missing',
         label: 'Missing fields',
         options: [{
            label: 'Missing fields (Yes)',
            filter: {
               hasErrors: true
            }
         }, {
            label: 'Missing fields (No)',
            filter: {
               hasErrors: false
            }
         }]
      }, {
         id: 'masterdata',
         label: 'Masterdata',
         options: [{
            label: 'Masterdata (Yes)',
            filter: {
               hasMasterData: true
            }
         }, {
            label: 'Masterdata (No)',
            filter: {
               hasMasterData: false
            }
         }]
      }, {
         id: 'stocklocation',
         label: 'Has stocklocation',
         options: [{
            label: 'Has stock location',
            filter: {
               hasLocation: true
            }
         }, {
            label: 'No stock location',
            filter: {
               hasLocation: false
            }
         }]
      }, {
         id: 'images',
         label: 'Has images',
         options: [{
            label: 'Has custom images',
            filter: {
               hasCustomImages: true
            }
         }, {
            label: 'No custom images',
            filter: {
               hasCustomImages: false
            }
         }]
      }, {
         id: 'testing',
         label: 'Is test',
         options: [{
            label: 'Yes',
            filter: {
               test: true
            }
         }, {
            label: 'No',
            filter: {
               test: false
            }
         }]
      }, {
         id: 'manufacturer',
         label: 'Valid vendor',
         options: [{
            label: 'Yes',
            filter: {
               invalidManufacturer: false
            }
         }, {
            label: 'No',
            filter: {
               invalidManufacturer: true
            }
         }]
      }, {
         id: 'sold',
         label: 'Sold',
         options: [{
            label: 'Yes',
            filter: {
               isSold: true
            }
         }, {
            label: 'No',
            filter: {
               isSold: false
            }
         }]
      }];

      if (columnOptions?.brand) {
         spec.push({
            id: 'brand',
            label: 'Brand',
            options: data?.BrandHouses?.map(brand => ({
               label: brand?.name || '',
               filter: {
                  brandHouseId: brand?._id
               }
            })) || []
         })
      }

      if (data?.BrandSettings?.tradeInComments) {
         spec.push(...[{
            id: 'comments',
            label: 'Has comments',
            options: [{
               label: 'Has comments',
               filter: {
                  hasComment: true
               }
            }, {
               label: 'No comments',
               filter: {
                  hasComment: false
               }
            }]
         }, {
            id: 'approvedComments',
            label: 'Approved comments',
            options: [{
               label: 'Approved comments',
               filter: {
                  commentApproved: true
               }
            }, {
               label: 'Unapproved comments',
               filter: {
                  commentApproved: false
               }
            }]
         }, {
            id: 'commentsTranslated',
            label: 'Translated comments',
            options: [{
               label: 'Translated comments',
               filter: {
                  hasTranslatedComment: true
               }
            }, {
               label: 'Non translated comments',
               filter: {
                  hasTranslatedComment: false
               }
            }]
         }])
      }
      return spec;
   }, [data, columnOptions]);

   const tabsSpec: FlexTab[] = [{
      id: 'Sale',
      label: 'Ready',
      filters: [{
         filterId: 'status',
         values: ['Ready']
      }, {
         filterId: 'sold',
         values: ['No']
      }, {
         filterId: 'missing',
         values: ['No']
      }, {
         filterId: 'problems',
         values: ['Problems (No)']
      }],
      columns: ['brand', 'id', 'image', 'title', 'beforePrice', 'stockLocation', 'stockLocationTs', 'status', 'vendor', 'shops']
   }, {
      id: 'Review',
      label: 'Need review',
      filters: [{
         filterId: 'status',
         values: ['Review']
      }, {
         filterId: 'sold',
         values: ['No']
      }],
      showCount: true,
      columns: ['brand', 'id', 'image', 'title', 'sku', 'beforePrice', 'originalEan', 'size', 'color', 'stockLocation', 'shops']
   }, {
      id: 'Images',
      label: 'Images',
      filters: [{
         filterId: 'images',
         values: ['Has custom images']
      }],
      columns: ['brand', 'id', 'imageExtended', 'title', 'originalEan', 'size', 'color', 'shops']
   }, {
      id: 'Sold',
      label: 'Sold',
      filters: [{
         filterId: 'sold',
         values: ['Yes']
      }],
      columns: ['brand', 'id', 'image', 'title', 'sku', 'beforePrice', 'status']
   }, {
      id: 'All',
      label: 'All',
      filters: [],
      columns: ['brand', 'id', 'image', 'title', 'sku', 'size', 'color', 'beforePrice', 'stockLocation', 'status', 'shops', 'note']
   }];

   if (isAdministrator) {
      const adminTab = {
         id: 'Masterdata',
         label: 'Need masterdata',
         filters: [{
            filterId: 'images',
            values: ['Has custom images']
         }, {
            filterId: 'masterdata',
            values: ['Masterdata (No)']
         }, {
            filterId: 'stocklocation',
            values: ['Has stock location']
         }, {
            filterId: 'sold',
            values: ['No']
         }],
         requireAll: true,
         showCount: true,
         columns: ['brand', 'id', 'image', 'title', 'sku', 'beforePrice', 'originalEan', 'size', 'color', 'stockLocation', 'shops']
      };

      const readyForSale = {
         id: 'readyForSale',
         label: 'Ready for sale',
         filters: [{
            filterId: 'images',
            values: ['Has custom images']
         }, {
            filterId: 'masterdata',
            values: ['Masterdata (Yes)']
         }, {
            filterId: 'stocklocation',
            values: ['Has stock location']
         }, {
            filterId: 'missing',
            values: ['Missing fields (No)']
         }, {
            filterId: 'problems',
            values: ['Problems (No)']
         }, {
            filterId: 'sold',
            values: ['No']
         }, {
            filterId: 'status',
            values: ['None', 'Draft', 'Review']
         }],
         requireAll: true,
         showCount: true,
         columns: ['brand', 'id', 'image', 'title', 'sku', 'beforePrice', 'originalEan', 'size', 'color', 'stockLocation', 'shops']
      };

      // Insert after the first tab
      tabsSpec.splice(2, 0, ...[adminTab, readyForSale]);
   }

   if (data?.BrandSettings?.tradeInComments) {
      const commentTab = {
         id: 'comments',
         label: 'Comments',
         filters: [{
            filterId: 'comments',
            values: ['Has comments']
         }],
         requireAll: true,
         showCount: true,
         columns: ['brand', 'id', 'image', 'title', 'comment', 'commentTranslated', 'commentApproved']
      }
      tabsSpec.splice(2, 0, ...[commentTab]);
   }

   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const effectiveFilter = Object.assign({}, filter, extraFilter)
      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManyBrandItemInput) || SortFindManyBrandItemInput._ID_DESC,
            perPage: PAGE_SIZE,
            search: search,
            filter: {
               brandHouseId: brandHouseId,
               ...effectiveFilter
            }
         }
      });

      const resultData = data?.BrandItemPagination?.items?.map(item => item!.resolved!) || []
      return {
         data: resultData,
         totalCount: data?.BrandItemPagination?.count || 0
      }
   }, [brandHouseId, extraFilter, fetchMore]);

   const locales = data?.BrandSettings?.locales?.filter(loc => loc?.activeItems).map(loc => loc!.locale);
   const currencies = data?.BrandSettings?.stockItemConfig?.currencies?.filter(loc => loc?.active).map(loc => loc!.currency)

   return <FlexList
      selections={true}
      globalId={listId || "brandItems"}
      tabs={headerOptions?.hide ? [] : tabsSpec}
      itemsPerPage={PAGE_SIZE}
      columns={columns}
      onRequestData={onRequestData}
      filters={filterSpec}
      sorts={sortSpec}
      options={{
         searchLabel: "Search",
         hideHeader: headerOptions?.hide
      }}
      commands={commands}
      context={{
         brandHouseId: brandHouseId,
         currency: currencies?.[0],
         locale: locales?.[0],
         locales: locales,
         currencies: currencies
      }}
      onSelectRow={onSelectItem}
   />
}
