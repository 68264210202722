import {useNavigate, useParams} from "react-router";
import {ParamBrandHouse, ParamShop} from "../../../Parameters";
import {DetailsPagePath} from "./DetailsPage";
import * as React from "react";
import {EnumIntegrationConnectorRefType} from "../../../Queries";
import {ShopIntegrationSettingsPath} from "./ShopIntegrationSettings";
import {RefIntegrations} from "./RefIntegrations";

export const ShopIntegrationsPagePath = (brandHouseId: string, shopId: string) => {
   return DetailsPagePath(brandHouseId) + '/' + shopId + '/integrations'
}

export const ShopIntegrations = () => {
   const {brandHouseId, shopId} = useParams<ParamBrandHouse & ParamShop>()
   const navigate = useNavigate();

   const onNavigateTo = (connectorId: string) => {
      navigate(ShopIntegrationSettingsPath(brandHouseId, shopId, connectorId))
   }

   return <RefIntegrations onBack onNavigateTo={onNavigateTo} refId={shopId}
                           title={"Shop integrations"}
                           refType={EnumIntegrationConnectorRefType.SHOP}/>
}

