import * as React from "react";
import {useState} from "react";
import {useObjectHistoryQuery, useObjectHistoryUserQuery} from "../../Queries";
import dayjs from "dayjs";
import {
   Timeline,
   TimelineConnector,
   TimelineContent,
   TimelineDot,
   TimelineItem,
   TimelineOppositeContent,
   TimelineSeparator
} from '@mui/lab';
import {Box, Tab, Tabs, Typography} from '@mui/material';
import {BodyText} from "../../layout/Typography";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../atoms/CurrentUser";

type ObjectHistoryProps = {
   refId: string
   secondary?: {
      refId: any
      title: string
   }[]
}

export const ObjectHistory = ({refId, secondary}: ObjectHistoryProps) => {
   const [activeTab, setActiveTab] = useState<number>(0);
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   if (!refId || !isAdministrator) return null;

   const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
   };

   const allTabs = [{refId, title: 'History'}, ...(secondary || [])];

   return (
      <Box sx={{width: '100%', marginTop: '30px'}}>
         <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="history tabs">
               {allTabs.map((tab, index) => (
                  <Tab label={tab.title + (index!==0 ? ' #' + index : '')} key={tab.refId}/>
               ))}
            </Tabs>
         </Box>
         {allTabs.map((tab, index) => (
            <TabPanel value={activeTab} index={index} key={tab.refId}>
               <ObjectHistoryInner refId={tab.refId}/>
            </TabPanel>
         ))}
      </Box>
   );
}

const TabPanel = (props: {
   children?: React.ReactNode;
   index: number;
   value: number;
}) => {
   const {children, value, index, ...other} = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`history-tabpanel-${index}`}
         aria-labelledby={`history-tab-${index}`}
         {...other}
      >
         {value === index && <Box sx={{p: 3}}>{children}</Box>}
      </div>
   );
};


const ObjectHistoryInner = ({refId}: ObjectHistoryProps) => {
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);
   const {data} = useObjectHistoryQuery({
      variables: {
         ref: refId
      }
   });

   if (!isAdministrator) {
      return null;
   }

   const patches = data?.PatchPagination?.items || [];

   return (
      <Box sx={{width: '100%'}}>
         <Timeline position="right">
            {patches.map((patch: any) => (
               <TimelineItem key={patch._id}>
                  {/* Date on the left side of the dot */}
                  <TimelineOppositeContent
                     sx={{flex: 0.2, paddingRight: "16px", textAlign: "right"}}
                  >
                     <Typography variant="body2" color="textSecondary">
                        {dayjs(patch.date).format("D MMM YYYY, HH:mm")}
                     </Typography>
                  </TimelineOppositeContent>

                  {/* Timeline dot and connector */}
                  <TimelineSeparator>
                     <TimelineDot/>
                     <TimelineConnector/>
                  </TimelineSeparator>

                  {/* Main content on the right side of the dot */}
                  <TimelineContent sx={{flex: 1, paddingLeft: "16px"}}>
                     <Box>
                        <BodyText type={"body1"} sx={{fontWeight: 'bold'}}>
                           {patch.updatedBy && <HistoryUser userId={patch.updatedBy.ref}/>}
                        </BodyText>
                        <ul>
                           {patch.ops.map((op: any, index: number) => (
                              <li key={index}>
                                 <Typography variant="body2">
                                    <PatchDescription {...op}/>
                                 </Typography>
                              </li>
                           ))}
                        </ul>
                     </Box>
                  </TimelineContent>
               </TimelineItem>
            ))}
         </Timeline>
      </Box>
   );
};

// Helper function to describe patch operations in human-readable format
type PatchDescriptionProps = {
   value?: any | null
   op?: string | null
   path?: string | null
}

const PatchDescription = ({value, path, op}: PatchDescriptionProps) => {
   const formattedPath = Array.isArray(path) ? path.join('.') : path; // Convert path array to dot notation string

   const renderPath = () => (
      <Typography
         component="span"
         sx={{
            color: 'red',
            backgroundColor: 'lightyellow',
            padding: '2px 4px',
            borderRadius: '4px',
            fontFamily: 'monospace'
         }}
      >
         {formattedPath}
      </Typography>
   );

   switch (op) {
      case 'add':
      case 'replace':
         return (
            <>
               <Typography variant="body2">
                  Updated {renderPath()} to:
               </Typography>
               <Box sx={{paddingLeft: 2}}>
                  <Box sx={{fontFamily: 'Courier'}}>
                     {JSON.stringify(value, null, 2)}
                  </Box>
               </Box>
            </>
         );
      case 'remove':
         return (
            <Typography variant="body2">
               Removed {renderPath()}
            </Typography>
         );
      default:
         return (
            <Typography variant="body2">
               Unknown operation {op}
            </Typography>
         );
   }
};


export const HistoryUser = ({userId}: { userId: string }) => {
   const {data} = useObjectHistoryUserQuery({
      variables: {
         id: userId
      }
   });
   return <div>{data?.User?.name || data?.User?.email || 'API / Brand'}</div>
}