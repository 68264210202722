import React, { FC } from 'react';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import { Tooltip, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

export const TrackingVisitorChip: FC<{ detailed: boolean; userAgent: string }> = ({ detailed, userAgent }) => {
   const classes = useUserStyles();

   let type: 'android' | 'apple' | 'desktop';
   let deviceInfo: string;

   if (/android/i.test(userAgent)) {
      type = 'android';
      deviceInfo = 'Android Device';
   } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      type = 'apple';
      deviceInfo = 'iOS Device';
   } else {
      type = 'desktop';
      deviceInfo = 'Desktop';
   }

   const getDetailedInfo = () => {
      const browser = getBrowserInfo(userAgent);
      const os = getOSInfo(userAgent);
      return `${deviceInfo}\nBrowser: ${browser}\nOS: ${os}\nUser Agent: ${userAgent}`;
   };

   const icon = {
      android: <AndroidIcon />,
      apple: <AppleIcon />,
      desktop: <DesktopWindowsIcon />
   }[type];

   return (
      <Tooltip title={detailed ? getDetailedInfo() : deviceInfo} arrow>
         <div className={classes.agentContainer}>
            {icon}
            {detailed && (
               <Typography variant="caption" className={classes.detailedInfo}>
                  {deviceInfo}
               </Typography>
            )}
         </div>
      </Tooltip>
   );
};

// Helper functions to extract more detailed information
const getBrowserInfo = (userAgent: string): string => {
   // Add logic to determine browser
   // This is a simplified example
   if (userAgent.includes('Chrome')) return 'Chrome';
   if (userAgent.includes('Firefox')) return 'Firefox';
   if (userAgent.includes('Safari')) return 'Safari';
   return 'Unknown';
};

const getOSInfo = (userAgent: string): string => {
   // Add logic to determine OS
   // This is a simplified example
   if (userAgent.includes('Win')) return 'Windows';
   if (userAgent.includes('Mac')) return 'MacOS';
   if (userAgent.includes('Linux')) return 'Linux';
   return 'Unknown';
};

const useUserStyles = makeStyles({
   agentContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      alignItems: 'center'
   },
   detailedInfo: {
      whiteSpace: 'nowrap'
   }
});
