import React from 'react';
import {InvoiceLine, useInvoiceLineTradeInQuery} from "../../../Queries";
import {Box, Card, CardContent, LinearProgress} from "@mui/material";
import {Thumbnails} from "../../../components/Thumbnails";
import {BodyText, TitleText} from "../../../layout/Typography";
import dayjs from "dayjs";
import {LinkTo} from "../../../components/LinkTo";
import {BrandTradeInPagePath} from "../../brand/tradeins/BrandTradeInPage";

export const InvoiceLineTradeIn = ({invoiceLine, tradeInId}: {
   invoiceLine: Partial<InvoiceLine>,
   tradeInId: string
}) => {
   const {data} = useInvoiceLineTradeInQuery({
      variables: {tradeInId}
   });

   if (!data) {
      return <LinearProgress/>;
   }

   const tradeIn = data.TradeInById;
   const firstImageForEachItem = tradeIn?.items?.map(item => item?.imageUrls?.[0]) || [];
   const validImages = firstImageForEachItem.filter(image => !!image) as string[];

   return (
      <Card sx={{display: 'flex', minWidth: '600px'}}>
         <Box sx={{width: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Thumbnails hideBorder
                        imageUrls={validImages}
                        size={100}
                        legendPosition={"right"}
            />
         </Box>
         <CardContent sx={{flex: '1 0 auto'}}>
            <TitleText type="h2">
               TradeIn : <LinkTo
               to={BrandTradeInPagePath(invoiceLine.brandHouseId, tradeIn?._id)}>{tradeIn?.tradeInNr || 'N/A'}</LinkTo>
            </TitleText>
            <BodyText type={"body1"}>
               Cost: {invoiceLine.cost || 'N/A'} {invoiceLine.currency}
            </BodyText>
            <BodyText type="subtitle2">
               {/* Replace with actual date when available */}
               Added to
               invoice {dayjs(invoiceLine.lineDate).format("DD. MMMM YYYY")} (Created {dayjs(tradeIn?.createdAt).format('DD-MM-YYYY')})
            </BodyText>
         </CardContent>
      </Card>
   );
};