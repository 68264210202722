import {useFieldArray, useFormContext} from "react-hook-form";
import {BrandEditorImage, BrandEditorItem} from "./BrandItemEditor";
import {
   Box,
   Card,
   CardActions,
   CardContent,
   Dialog,
   DialogActions,
   DialogContent,
   Divider,
   ListItemIcon,
   ListItemText,
   Menu,
   MenuItem
} from "@mui/material";
import {Thumbnail} from "../../../components/Thumbnail";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MenuIcon from "@mui/icons-material/Menu";
import {useItemEditorImage2LazyQuery, useItemEditorImageLazyQuery} from "../../../Queries";
import {useEffect, useState} from "react";
import {CheckBox, CheckBoxOutlineBlank, Edit} from "@mui/icons-material";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import {BrandItemImageEditor} from "./BrandItemImageEditor";
import {UploadImage} from "../../../components/UploadImage";
import {createUploadService} from "../../../UploadService";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {BrandItemImportDialog} from "./BrandItemImportDialog";
import {ItemImageStatus} from "./ItemImageStatus";
import {BrandProductImage} from "./BrandProductImage";

type BrandItemImagesProps = {
   brandHouseId: string
   brandProductSizeId?: string
   brandItemId?: string
}

export const BrandItemImages = ({brandHouseId, brandProductSizeId, brandItemId}: BrandItemImagesProps) => {
   const {control, setValue} = useFormContext<BrandEditorItem>();
   const [importing, setImporting] = useState<boolean>(false);
   const [editAltText, setEditAltText] = useState<number | undefined>(undefined);

   const {fields: images, append, remove, move, update} = useFieldArray({
      control,
      name: "images",
   });

   const onUploadImages = (imageIds: string[] | string) => {
      for (const imageId of imageIds) {
         append({
            uploadId: imageId,
            process: false,
            originalImage: false
         })
      }
   }

   const onImport = () => {
      setImporting(true);
   }

   const uploadService = createUploadService(process.env.REACT_APP_FILESERVICEURL!, brandHouseId)

   return <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
      {images.map((image, index) => {
         return <Box key={image._id || 'i_' + index}>
            <ItemImage
               brandHouseId={brandHouseId}
               image={image}
               onDelete={() => remove(index)}
               onMoveUp={() => move(index, index - 1)}
               onMoveDown={() => move(index, index + 1)}
               onUpdate={(newImageId: string) => {
                  update(index, {
                     ...image,
                     uploadId: newImageId,
                     imageId: null
                  } as any);
               }}
               onToggleBackground={() => {
                  update(index, {
                     ...image,
                     process: !image.process,
                     imageId: null
                  } as any);
               }}
               onToggleOriginal={() => {
                  update(index, {
                     ...image,
                     originalImage: !image.originalImage,
                     process: true
                  } as any);
               }}
               onUpdateImageId={(imageId) => {
                  setValue(`images.${index}.imageId`, imageId, {
                     shouldTouch: true,
                     shouldDirty: false
                  });
               }}
            />
         </Box>
      })}
      <UploadImage id={"brandItemImageUpload"}
                   size={220}
                   uploadService={uploadService}
                   addImages={onUploadImages}>
         <Button variant={"outlined"} onClick={onImport} style={{marginLeft: '10px'}}
                 disabled={!brandProductSizeId}>Import</Button>
      </UploadImage>
      <Dialog open={editAltText !== undefined} onClose={() => setEditAltText(undefined)}>
         <DialogContent>
            <FormInputText name={`images[${editAltText}].altText`} label={"Image alt text"}/>
         </DialogContent>
         <DialogActions>
            <Button variant={"contained"} onClick={() => setEditAltText(undefined)}>Update</Button>
         </DialogActions>
      </Dialog>
      {(importing && brandItemId) &&
         <BrandItemImportDialog brandItemId={brandItemId}
                                onSelect={img => {
                                   append({
                                      brandProductImageId: img.imageId,
                                      originalImage: true
                                   });
                                }}
                                onHide={() => setImporting(false)}/>
      }
   </Box>
}

type BrandItemImageProps = {
   brandHouseId: string
   image: BrandEditorImage
   onDelete: () => void
   onMoveUp: () => void
   onMoveDown: () => void
   onUpdate: (newImageId: string) => void
   onToggleBackground: () => void
   onToggleOriginal: () => void
   onUpdateImageId: (imageId: string) => void
}

type ImageStorage = {
   imageUrl?: string
   uploadUrl?: string
}

export const ItemImage = ({
                             brandHouseId,
                             image,
                             onUpdate,
                             onMoveUp,
                             onMoveDown,
                             onDelete,
                             onToggleBackground,
                             onToggleOriginal,
                             onUpdateImageId
                          }: BrandItemImageProps) => {
   const [imageStorage, setImageStorage] = useState<ImageStorage>({});
   const [showOther, setShowOther] = useState<boolean>(false);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [showMenu, setShowMenu] = useState<boolean>(false);
   const [editImageId, setEditImageId] = useState<string | undefined>(undefined);

   const [fetchUrl] = useItemEditorImageLazyQuery();
   const [fetchUrl2] = useItemEditorImage2LazyQuery();

   useEffect(() => {
      if (image.imageId || image.uploadId) {
         (async function () {
            let imgUrl = '';
            let uplUrl = '';

            if (image.imageId) {
               const {data: imageData} = await fetchUrl2({
                  variables: {
                     imageId: image.imageId
                  }
               });
               imgUrl = imageData?.StorageFileById?.url || '';
            }

            if (image.uploadId) {
               const {data: imageData} = await fetchUrl({
                  variables: {
                     imageId: image.uploadId
                  }
               });
               uplUrl = imageData?.Image?.url || '';
            }

            setImageStorage({
               imageUrl: imgUrl,
               uploadUrl: uplUrl
            });
         })();
      }
   }, [fetchUrl, fetchUrl2, image.imageId, image.uploadId]);

   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setShowMenu(true);
      setAnchorEl(event.currentTarget);
   };

   const onUpdateImage = async (newImageId: string) => {
      onUpdate(newImageId);
      setImageStorage({}); // Reset image storage to trigger a re-fetch
      setEditImageId(undefined);
   };

   return (
      <Box>
         <Card sx={{ width: 245, marginBottom: '15px', marginLeft: '7px', marginRight: '7px', position: 'relative', borderColor: '#f0f0f0' }} variant={"outlined"}>
            <CardContent sx={{ height: '14px', padding: '0', textAlign: 'center' }}>
               {imageStorage.imageUrl && imageStorage.uploadUrl &&
                  <Box sx={{ color: '#a0a0a0', cursor: 'pointer', userSelect: 'none' }} onClick={() => setShowOther(!showOther)}>
                     {showOther ? 'hide original' : 'view original'}
                  </Box>}
            </CardContent>
            <CardContent sx={{ position: 'relative' }}>
               {image.brandProductImageId && <BrandProductImage brandProductImageId={image.brandProductImageId} />}
               {!image.brandProductImageId && <>
                  <Thumbnail image={ (imageStorage.imageUrl && !showOther) ? imageStorage.imageUrl : imageStorage.uploadUrl } size={200} hideBorder={true} />
                  {image._id && <ItemImageStatus sx={{ position: 'absolute', bottom: '5px', left: '5px', right: '5px', }} imageId={image._id} process={image.process || false} onUpdateImageId={onUpdateImageId} />}
               </>}
            </CardContent>
            <CardActions>
               <Grid container>
                  <Grid item xs={12} container justifyContent={"space-between"}>
                     <Button variant={"outlined"} size="small" color="secondary" onClick={handleClick}><MenuIcon /></Button>
                     {!image.brandProductImageId && <Button variant={"outlined"} size="small" color="secondary" onClick={() => setEditImageId(image.uploadId)}>
                        <Edit />
                     </Button>}

                     <Button className={"tradein-blackbutton"} variant={"outlined"} size="small" color="secondary" onClick={onDelete}>
                        <DeleteForeverIcon />
                     </Button>
                  </Grid>
               </Grid>
            </CardActions>
         </Card>
         {showMenu &&
            <Menu
               id={"long-menu"}
               MenuListProps={{ 'aria-labelledby': 'long-button' }}
               anchorEl={anchorEl}
               open={open}
               onClose={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAnchorEl(null);
               }}
            >
               {!image.brandProductImageId && [
                  <MenuItem onClick={onToggleBackground}>
                     <ListItemIcon>
                        {image.process ? <CheckBox fontSize="small" /> : <CheckBoxOutlineBlank fontSize={"small"} />}
                     </ListItemIcon>
                     <ListItemText>Remove background</ListItemText>
                  </MenuItem>,
                  <Divider />
               ]}
               {image.uploadId && [
                  <MenuItem onClick={onToggleOriginal}>
                     <ListItemIcon>
                        {image.originalImage ? <CheckBox fontSize="small" /> : <CheckBoxOutlineBlank fontSize={"small"} />}
                     </ListItemIcon>
                     <ListItemText>Original product image</ListItemText>
                  </MenuItem>,
                  <Divider />
               ]}
               <MenuItem onClick={onMoveUp}>
                  <ListItemIcon><MoveUpIcon fontSize="small" /></ListItemIcon>
                  <ListItemText>Move up</ListItemText>
               </MenuItem>
               <MenuItem onClick={onMoveDown}>
                  <ListItemIcon><MoveDownIcon fontSize="small" /></ListItemIcon>
                  <ListItemText>Move down</ListItemText>
               </MenuItem>
            </Menu>
         }

         {editImageId &&
            <BrandItemImageEditor imageId={editImageId} brandHouseId={brandHouseId} show={!!editImageId} onClose={() => setEditImageId(undefined)} onUpdate={onUpdateImage} />
         }
      </Box>
   );
};
