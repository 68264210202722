import * as React from "react";
import {FC, useState} from "react";
import {
   EnumBrandSettingStockItemConfigImageSorting,
   EnumBrandSettingStockItemConfigMasterImageTypes,
   EnumBrandSettingStockItemConfigSkuStrategy,
   SettingsStockItemFragment,
   useBasicSettingsShopQuery,
   useBasicSettingsStockQuery,
   useBasicSettingsStockUpdateMutation
} from "../../../Queries";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {BodyText} from "../../../layout/Typography";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {FormInputSelect} from "../../shop/wholesale/form/FormInputSelect";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {FormInputHtml} from "../../shop/wholesale/form/FormInputHtml";
import {SavingUI} from "../../../SavingProvider";
import {Dirty, DirtyDetector, ReactHookDirtyDetector} from "../../../components/DirtyDetector";
import {LocalizationEditor} from "../../shop/components/LocalizationEditor";
import {Wildcards} from "./Wildcards";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import PageContent from "../../../layout/PageContent";
import {FormInputNumber} from "../../shop/wholesale/form/FormInputNumber";
import {FormInputSelectMultiple} from "../../shop/wholesale/form/FormInputSelectMultiple";

const ID_STOCKFORM = "stocksettingsform";

const BasicItemSettingsPath = (brandId: string, shopId: string) => {
   return "/brands/" + brandId + "/settings/basic/" + shopId
}

export const BasicItemSettings = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(BasicItemSettingsPath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Title and texts"}
                      subtitle={"Use this section to customize general settings for stockitems"}>
         <Button variant={"contained"} type={"submit"} form={ID_STOCKFORM}>
            Save
         </Button>
      </SettingsHeader>
      <ShopTabs
         activeId={shopId}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <BasicItemSettingsInnerCore brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <BasicItemSettingsInner shopId={shop}/>}
      />
   </div>
}

type BasicItemSettingsInnerProps = {
   shopId?: string
   brandHouseId?: string
}

const BasicItemSettingsInner = ({shopId}: BasicItemSettingsInnerProps) => {
   const {data: staticData} = useBasicSettingsShopQuery({
      variables: {
         shopId: shopId
      }
   })

   const {data: settings} = useBasicSettingsStockQuery({
      variables: {
         shopId: shopId
      },
      fetchPolicy: "no-cache"
   })

   const [saveSettings] = useBasicSettingsStockUpdateMutation();
   const onSave = async (data: SettingsStockItemFragment) => {
      await SavingUI.process(async () => {
         await saveSettings({
            variables: {
               shopId: shopId,
               stockConfig: data
            }
         });
         Dirty.reset()
      }, 'Stock settings updated')
   }

   if (!staticData || !settings) {
      return <LinearProgress/>
   }

   return <>
      <StockSettingsEditor
         primaryLocale={staticData.ShopById!.stockItemLocalePrimary}
         locales={staticData.ShopById!.stockItemLocales as string[]}
         data={settings.BrandSettings!.stockItemConfig!}
         onSave={onSave}
      />
      <DirtyDetector/>
   </>
}

const BasicItemSettingsInnerCore = ({brandHouseId}: BasicItemSettingsInnerProps) => {
   const {data: settings} = useBasicSettingsStockQuery({
      variables: {
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })

   const [saveSettings] = useBasicSettingsStockUpdateMutation();
   const onSave = async (data: SettingsStockItemFragment) => {
      await SavingUI.process(async () => {
         await saveSettings({
            variables: {
               brandHouseId: brandHouseId,
               stockConfig: data
            }
         });
         Dirty.reset()
      }, 'Stock settings updated')
   }

   if (!settings) {
      return <LinearProgress/>
   }

   return <>
      <StockSettingsEditor
         data={settings.BrandSettings!.stockItemConfig!}
         onSave={onSave}
      />
      <DirtyDetector/>
   </>
}


type StockSettingsEditorProps = {
   primaryLocale?: string
   locales?: string[]
   data: SettingsStockItemFragment
   onSave: (data: SettingsStockItemFragment) => Promise<void>
}

const StockConfigurationSchema = yup.object().shape({
   titlePrefix: yup.string().required('Please enter a prefix'),
   titleSeperator: yup.string().nullable(),
   appendColor: yup.boolean().nullable(),
   appendSize: yup.boolean().nullable(),
   descriptionSuffix: yup.string().nullable(),
   imageAltText: yup.string().nullable()
})

const StockSettingsEditor: FC<StockSettingsEditorProps> = ({
                                                              primaryLocale,
                                                              locales,
                                                              data,
                                                              onSave
                                                           }) => {
   const [showTranslations, setShowTranslations] = useState<boolean>(false);

   const methods = useForm<SettingsStockItemFragment>({
      resolver: yupResolver(StockConfigurationSchema),
      defaultValues: data,
      reValidateMode: "onChange"
   });

   const {handleSubmit} = methods;

   const Localizations = () => {
      return <button onClick={() => setShowTranslations(true)}>Edit translations</button>
   }

   let showShopSettings = primaryLocale && locales;

   return <div>
      <form onSubmit={handleSubmit(onSave)} id={ID_STOCKFORM}/>
      <FormProvider {...methods}>
         <ReactHookDirtyDetector/>
         {showShopSettings &&
         <Dialog open={showTranslations} fullWidth maxWidth={"lg"} onClose={() => setShowTranslations(false)}>
            <DialogTitle>Localization editor</DialogTitle>
            <DialogContent>
               <LocalizationEditor primaryLocale={primaryLocale!} locales={locales!} fields={[{
                  title: 'Stock item description',
                  field: 'descriptionSuffix',
                  multiline: true
               }]}/>
            </DialogContent>
            <DialogActions>
               <Button variant={"contained"} onClick={() => setShowTranslations(false)}>Update</Button>
            </DialogActions>
         </Dialog>
         }

         <PageContent>
            <BodyText type={"subtitle1"}>
               Stockitem texts configurations
            </BodyText>
            <Grid container gap={1}>
               <Grid item xs={12}>
                  <FormInputText name={"titlePrefix"} label={"Prefix"}/>
               </Grid>
               <Grid item xs={12}>
                  <FormInputText name={"titleAdvanced"} label={"Title format"}/>
               </Grid>
            </Grid>
            <Grid container gap={1} wrap={"nowrap"}>
               <Grid item xs={3}>
                  <FormInputCheckbox name={"appendColor"}
                                     label={"Append color to title (title - color)"}/>
               </Grid>
               <Grid item xs={3}>
                  <FormInputCheckbox name={"appendSize"} label={"Append size to title (title - size)"}/>
               </Grid>
               <Grid item xs={3}>
                  <FormInputSelect name={"titleSeperator"} label={"Prefix seperator"}
                                   options={[{
                                      id: '-',
                                      name: 'Dash (-)'
                                   }, {
                                      id: '/',
                                      name: 'Slash (/)'
                                   }]}
                  />
               </Grid>
               <Grid item xs={3}>
                  <FormInputSelect name={"appendSeperator"} label={"Suffix seperator"}
                                   options={[{
                                      id: '-',
                                      name: 'Dash (-)'
                                   }, {
                                      id: '/',
                                      name: 'Slash (/)'
                                   }]}/>
               </Grid>
            </Grid>
            <br/>

            {showShopSettings && <><br/><BodyText type={"subtitle1"}>
               Stock item description
            </BodyText>
               <Grid container gap={1} wrap={"nowrap"}>
                  <Grid item xs={12}>
                     <FormInputHtml name={"descriptionSuffix"}
                                    label={"Appendage to the stockitem description"}
                                    toolbarButton={[<Wildcards/>, <Localizations/>]}
                     />
                  </Grid>
               </Grid><br/></>}
            <BodyText type={"subtitle1"}>
               Storefront settings
            </BodyText>
            <Grid container gap={1} wrap={"nowrap"}>
               <Grid item xs={12}>
                  <FormInputText name={"template"} label={"Stock item template"}/>
               </Grid>
               <Grid item xs={12}>
                  <FormInputText name={"vendor"} label={"Stock item default vendor"}/>
               </Grid>
            </Grid>

            <br/>
            <br/>
            <BodyText type={"subtitle1"}>
               Images
            </BodyText>
            <Grid container gap={1} wrap={"nowrap"}>
               <Grid item xs={4}>
                  <FormInputSelect name={"imageSorting"}
                                   options={Object.values(EnumBrandSettingStockItemConfigImageSorting).map(sort => {
                                      return {
                                         id: sort,
                                         name: sort
                                      }
                                   })} label={"Sort behaviour"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputText name={"imageAltText"} label={"Image alt texts (Default)"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputText name={"originalImageAltText"} label={"Original image alt texts (Default)"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputNumber name={"imageRotate"} label={"Automatic rotation of image (0-359)"}
                                   placeholder={"270"}/>
               </Grid>
            </Grid>

            <br/>
            <br/>
            <BodyText type={"subtitle1"}>
               Overrides
            </BodyText>
            <Grid container gap={1} wrap={"nowrap"}>
               <Grid item xs={4}>
                  <FormInputText name={"overrides.productType"} label={"Product type (override)"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputText name={"overrides.vendor"} label={"Vendor (override)"}/>
               </Grid>
            </Grid>
            <BodyText type={"subtitle1"}>
               SKU Strategy
            </BodyText>
            <Grid container gap={1} wrap={"nowrap"}>
               <Grid item xs={4}>
                  <FormInputSelect name={"skuStrategy"} label={"The SKU generation strategy"} options={Object.keys(EnumBrandSettingStockItemConfigSkuStrategy).map(sku => ({
                     id: sku,
                     name: sku
                  }))}/>
               </Grid>
            </Grid>

            <br/>
            <br/>
            <BodyText type={"subtitle1"}>
               Auto images
            </BodyText>
            <Grid container gap={1} wrap={"nowrap"}>
               <Grid item xs={4}>
                  <FormInputNumber name={"masterImageCount"} label={"Number of images to add from master"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputSelectMultiple name={"masterImageTypes"} label={"Image types to add from master"} options={Object.keys(EnumBrandSettingStockItemConfigMasterImageTypes).map(key => ({
                     id: key,
                     label: key
                  }))}/>
               </Grid>
            </Grid>

         </PageContent>
      </FormProvider>
   </div>
}
