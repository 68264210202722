import React from 'react';
import {Alert, Button, Stack} from '@mui/material';

interface ReturnedItemStatusProps {
   returned: boolean;
   unavailable?: boolean;
   onMakeAvailable: () => void;
}

const ReturnedItemStatus: React.FC<ReturnedItemStatusProps> = ({returned, unavailable, onMakeAvailable}) => {
   if (!returned) return null;

   return (
      <Stack spacing={2}>
         <Alert severity="info" color={unavailable ? "warning" : "success"}>
            Item returned {unavailable && <>- but not yet available</>}
         </Alert>
         {unavailable && <>
            <Button variant={"contained"} size="small" onClick={onMakeAvailable}>
               Make item available
            </Button>
         </>}
      </Stack>
   );
};

export default ReturnedItemStatus;