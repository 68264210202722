import React from 'react';
import {InvoiceLine, useInvoiceLineOrderQuery} from "../../../Queries";
import {Box, Card, CardContent, LinearProgress} from "@mui/material";
import {Thumbnails} from "../../../components/Thumbnails";
import dayjs from "dayjs";
import {BodyText, TitleText} from "../../../layout/Typography";
import {LinkTo} from "../../../components/LinkTo";
import {OrderPagePath} from "../../shop/orders/OrderPage";

export const InvoiceLineCommission = ({invoiceLine, orderLineId}: {
   invoiceLine: Partial<InvoiceLine>,
   orderLineId: string
}) => {
   const {data} = useInvoiceLineOrderQuery({
      variables: {
         lineId: orderLineId
      }
   });

   if (!data) {
      return <LinearProgress/>;
   }

   const line = data.OrderLineById;
   const order = line?.order;

   return (
      <Card sx={{display: 'flex', minWidth: '600px'}}>
         <Box sx={{width: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Thumbnails legendPosition={"off"} hideBorder imageUrls={line?.stockItem?.imageUrls || []} size={100}
                        maxImages={1}/>
         </Box>
         <CardContent sx={{flex: '1 0 auto'}}>
            <TitleText type="h2">
               Order: <LinkTo to={OrderPagePath(invoiceLine.brandHouseId, line?.stockItem?.shopId, line?.order?._id)}>{order?.orderName}</LinkTo>
            </TitleText>
            <BodyText type={"body1"} sx={{fontWeight: 'bold', marginBottom: '5px'}}>
               {line?.stockItem?.title}
            </BodyText>
            <BodyText type={"body1"}>
               Cost: {invoiceLine.cost || 'N/A'} {invoiceLine.currency} {line?.discountedPricePreTax &&
               <span>(% of {line.discountedPricePreTax} {invoiceLine.currency})</span>}
            </BodyText>
            <BodyText type="subtitle2">
               {/* Replace with actual date when available */}
               Added to invoice {dayjs(invoiceLine.lineDate).format("DD. MMMM YYYY")} (Ordered {dayjs(order?.orderDate).format('DD-MM-YYYY')})
            </BodyText>
         </CardContent>
      </Card>
   );
};