import {Box, Checkbox, Dialog, DialogContent, FormControlLabel, LinearProgress} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import {FormInputText} from "../wholesale/form/FormInputText";
import Button from "@mui/material/Button";
import {EnumOptionSetTarget, useOrderUpdateReasonsQuery} from "../../../Queries";
import {FormInputCheckbox} from "../wholesale/form/FormInputCheckbox";

type UpdateType = 'return' | 'cancel';

type OrderUpdateReasonProps = {
   brandHouseId: string
   updateType: UpdateType
   open: boolean
   onUpdate: (updateType: UpdateType, reason: string, unavailble: boolean) => void
   onClose: () => void
}

export const OrderUpdateReason = ({brandHouseId, updateType, open, onUpdate, onClose}: OrderUpdateReasonProps) => {
   const [reason, setReason] = React.useState<string>('');

   type OrderUpdateReasonData = {
      reason: string
      unavailble: boolean
   }
   const {data} = useOrderUpdateReasonsQuery({
      variables: {
         target: updateType === "return" ? EnumOptionSetTarget.RETURNCODE : EnumOptionSetTarget.CANCELCODE,
         brandHouseId
      }
   });

   const form = useForm<OrderUpdateReasonData>({
      defaultValues: {
         reason: ''
      }
   });
   const {setValue} = form;

   if (!data) {
      return <LinearProgress/>
   }

   const onSetReason = (val: { value: string }) => () => {
      setReason(val.value)
      setValue('reason', val.value);
   }


   return <Dialog open={open} maxWidth={"md"} onClose={onClose}>
      <DialogContent>
         <FormProvider {...form}>
            {data?.Options?.map(option => {
               return <Box sx={{display: 'flex', gap: '2px', flexWrap: 'wrap'}}
                           key={"options_" + option._id}>{option.values?.map(value => {
                  // Button for each option, when button clicked insert option.label into form
                  return <FormControlLabel control={<Checkbox checked={value?.value === reason}/>}
                                           sx={{width: '45%'}}
                                           key={"optionvalue_" + value!._id}
                                           onClick={onSetReason(value!)} label={value?.value + ' - ' + value?.label}
                  />
               })
               }</Box>
            })}
            <FormInputText name={'reason'} label={'Please provide reason'} required/>
            <FormInputCheckbox name={"unavailble"} label={"Item unavailable?"}/>
            <Button variant={"contained"} onClick={() => {
               const values = form.getValues();
               onUpdate(updateType, values.reason, values.unavailble)
            }}>Select</Button>
         </FormProvider>
      </DialogContent>
   </Dialog>
}
