import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {FC, useEffect, useState} from "react";
import Page from "../../../../layout/Page";
import PageHeader from "../../../../layout/PageHeader";
import PageContent from "../../../../layout/PageContent";
import {FormControlLabel, Grid, Link, Radio, RadioGroup} from "@mui/material";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import {DTCViewPath} from "../view/DTCViewPage";
import {SettingsGroup} from "../../../../layout/SettingsGroup";
import makeStyles from '@mui/styles/makeStyles';
import {BodyText} from "../../../../layout/Typography";
import {LocalizationProvider} from '@mui/x-date-pickers';
import '@mui/lab';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {ParamBrandHouse, ParamBrandRuleId, ParamShop} from "../../../../Parameters";
import {FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {
   BrandRuleVoucherAmounts,
   DtcEditBrandFragment,
   DtcEditBrandRuleDocument,
   DtcEditBrandRuleFragment,
   DtcPotentialsListPaginationDocument,
   EnumBrandRuleDigitalPassportValue,
   EnumBrandRuleMinimumPriceType,
   EnumBrandRuleSecondhandVoucherValue,
   EnumBrandRuleVoucherStrategy,
   EnumBrandRuleVoucherValue,
   useDtcEditBrandByIdQuery,
   useDtcEditBrandRuleQuery,
   useDtcEditBrandRuleUpdateMutation,
   useVoucherEditorTagsQuery
} from "../../../../Queries";
import {FormInputDate} from "../../wholesale/form/FormInputDate";
import {FormInputNumber} from "../../wholesale/form/FormInputNumber";
import {ProductEditor} from "./views/ProductEditor";
import DeleteIcon from '@mui/icons-material/Delete';
import {FormInputAutocomplete} from "../../wholesale/form/FormInputAutocomplete";
import {FormInputSelect} from "../../wholesale/form/FormInputSelect";

type DTCEditProps = {}

export const DTCEditPath = (brandHouseId: string, shopId: string, brandRuleId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/dtc/" + brandRuleId + "/edit";
}

export const DTCEditPage = (props: DTCEditProps) => {
   return <Page>
      <PageHeader title={"Edit"}/>
      <PageContent>
         <BrandRuleEdit/>
      </PageContent>
   </Page>
}

export const BrandRuleEdit: FC<any> = () => {
   const {brandHouseId, shopId, brandRuleId} = useParams<ParamBrandHouse & ParamShop & ParamBrandRuleId>();

   const {data: brandRule} = useDtcEditBrandRuleQuery({
      variables: {
         ruleId: brandRuleId
      }
   });

   const {data: brand} = useDtcEditBrandByIdQuery({
      variables: {
         shopId: shopId
      }
   })

   const [brandRuleUpdate] = useDtcEditBrandRuleUpdateMutation();
   const navigate = useNavigate();

   const onSave = async (brandRule: DtcEditBrandRuleFragment) => {
      await brandRuleUpdate({
         variables: {
            rule: brandRule
         },
         refetchQueries: [{
            query: DtcEditBrandRuleDocument,
            variables: {
               ruleId: brandRuleId
            }
         }, DtcPotentialsListPaginationDocument]
      });
      navigate(DTCViewPath(brandHouseId, shopId, brandRuleId));
   }

   const onCancel = () => {
      navigate(DTCViewPath(brandHouseId, shopId, brandRuleId));
   }

   if (!brandRule || !brand) {
      return <LinearProgress/>
   }

   return <BrandRuleEditInner2
      brandRule={brandRule.BrandRule!} shop={brand.ShopById!} onSave={onSave}
      onCancel={onCancel}/>
}

const MinimumPriceLabels = {
   [EnumBrandRuleMinimumPriceType.RRP]: "Recommended retail price (RRP)",
   [EnumBrandRuleMinimumPriceType.SP]: "Sales price after discounts (SP)",
   [EnumBrandRuleMinimumPriceType.SPORIGINAL]: "Sales price before discounts (SPORIGINAL)"
}

type BrandRuleEditInnerProps = {
   brandRule: DtcEditBrandRuleFragment,
   shop: DtcEditBrandFragment,
   onSave: (rule: DtcEditBrandRuleFragment) => Promise<void>
   onCancel: () => void
};

const BrandRuleEditInner2: FC<BrandRuleEditInnerProps> = ({brandRule, shop, onSave, onCancel}) => {
   const classes = useSettingStyles();

   const methods = useForm<DtcEditBrandRuleFragment>({
      defaultValues: brandRule
   });
   const {handleSubmit, setValue, watch} = methods;

   const voucherValue = watch('voucherValue')
   const voucherStrategy = watch('voucherStrategy')

   const secondHandVoucherValue = watch('secondhandVoucherValue')
   const digitalPassportValue = watch('digitalPassportValue')

   const onFormSave = async (data: DtcEditBrandRuleFragment) => {
      onSave(data);
   }

   return <FormProvider {...methods}>
      <form className={classes.root} noValidate autoComplete="off">
         <div className={classes.panel}>
            <Grid container justifyContent="space-between" alignItems={"center"} style={{marginBottom: 20}}>
               <div></div>
               <div>
                  <Button variant={"contained"} color={"primary"} onClick={handleSubmit(onFormSave)}>Save</Button>
                  <Button variant={"outlined"} color={"primary"} onClick={onCancel}>Cancel</Button>
               </div>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
               <SettingsGroup title={"Order date"}
                              description={"Orders within this period of time will be included. If no period is given, all orders will be included."}>
                  <FormInputDate name={"periodFrom"} label={"From"}/>
                  <FormInputDate name={"periodTo"} label={"To"}/>
               </SettingsGroup>
            </LocalizationProvider>

            <SettingsGroup title={"Offer generation"}
                           description={"How many days after the order should an offer be generated?"}>
               <FormInputNumber name={"minimumAge"} label={"Not before (days)"} placeholder={"None"}/>
               <FormInputNumber name={"maximumAge"} label={"Stop after (days)"} placeholder={"None"}/>
            </SettingsGroup>

            <SettingsGroup title={"Email marketing delay"}
                           description={"How many days to wait until the generated offer is emailed to the consumer"}>
               <FormInputNumber
                  name={"marketingDelay"}
                  label={"Email marketing delay (days)"}
                  placeholder={"No delay"}
               />
            </SettingsGroup>

            <SettingsGroup title={"Minimum price"}
                           description={"Only products that has a price above the minimum value will be included"}>
               <FormInputNumber
                  name={"minimumPrice"}
                  label={"Minimum price (" + shop?.currency + ")"}
                  placeholder={"None"}
               />
               <FormInputSelect
                  name={"minimumPriceType"}
                  label={"Minimum price type"}
                  placeholder={"None"}
                  options={Object.values(EnumBrandRuleMinimumPriceType).map(key => {
                     return {
                        id: key,
                        name: MinimumPriceLabels[key]
                     }
                  })}
               />
            </SettingsGroup>

            <SettingsGroup title={"Products"} description={"Options for matching products"}>
               <ProductEditor shopId={brandRule.shopId}/>
            </SettingsGroup>

            <SettingsGroup title={"TradeIn for new styles"}
                           description={"How much do customers get when they tradein styles that was bought as new"}>
               <div>
                  <FormInputNumber name={"tradeInPricePct"} label={"Voucher pct%"} placeholder={"0-100%"}/>

                  <VoucherPricingEditor shopId={shop._id}/>
                  <br/>

                  <div>
                     <BodyText type={"subtitle1"}>
                        Pricing based on
                     </BodyText>
                     <RadioGroup aria-label="voucherValue" name="voucherValue" value={voucherValue}
                                 onChange={(e) => setValue('voucherValue', e.target.value as EnumBrandRuleVoucherValue)}
                     >
                        <FormControlLabel value="RRP" control={<Radio color={"primary"}/>}
                                          label="RRP - Recommended Sales Price"/>
                        <FormControlLabel value="SPORIGINAL" control={<Radio color={"primary"}/>}
                                          label="SP - Sales Price (before discounts)"/>
                        <FormControlLabel value="SP" control={<Radio color={"primary"}/>}
                                          label="SP - Sales Price (after discounts)"/>
                     </RadioGroup>
                  </div>
               </div>
            </SettingsGroup>

            <SettingsGroup title={"TradeIn for " + shop.settings?.stockItemConfig?.titlePrefix + ' styles'}
                           description={"How much do customers get when they trade in " + shop.settings?.stockItemConfig?.titlePrefix + ' styles'}>
               <div>
                  <FormInputNumber name={"secondhandTradeInPct"} label={"Voucher pct%"} placeholder={"0-100%"}/>

                  <br/>
                  <div>
                     <BodyText type={"subtitle1"}>
                        Pricing based on
                     </BodyText>
                     <RadioGroup aria-label="secondhandVoucherValue" name="secondhandVoucherValue"
                                 value={secondHandVoucherValue}
                                 onChange={(e) => setValue('secondhandVoucherValue', e.target.value as EnumBrandRuleSecondhandVoucherValue)}
                     >
                        <FormControlLabel value="RRP" control={<Radio color={"primary"}/>}
                                          label="RRP - Recommended Sales Price"/>
                        <FormControlLabel value="SPORIGINAL" control={<Radio color={"primary"}/>}
                                          label="SP - Sales Price (before discounts)"/>
                        <FormControlLabel value="SP" control={<Radio color={"primary"}/>}
                                          label="SP - Sales Price (after discounts)"/>
                     </RadioGroup>
                  </div>
               </div>
            </SettingsGroup>

            {shop.settings?.digitalPassportActive &&
            <SettingsGroup title={"TradeIn for digital passport styles"}
                           description={"How much do customers get when they trade in digital passport styles"}>
               <div>
                  <FormInputNumber name={"digitalPassportPct"} label={"Voucher pct%"} placeholder={"0-100%"}/>

                  <br/>
                  <div>
                     <BodyText type={"subtitle1"}>
                        Pricing based on
                     </BodyText>
                     <RadioGroup aria-label="digitalPassportValue" name="digitalPassportValue"
                                 value={digitalPassportValue}
                                 onChange={(e) => setValue('digitalPassportValue', e.target.value as EnumBrandRuleDigitalPassportValue)}
                     >
                        <FormControlLabel value="RRP" control={<Radio color={"primary"}/>}
                                          label="RRP - Recommended Sales Price"/>
                        <FormControlLabel value="SP" control={<Radio color={"primary"}/>}
                                          label="SP - Sales Price"/>
                     </RadioGroup>
                  </div>
               </div>
            </SettingsGroup>}

            <SettingsGroup title={"Voucher activation"}
                           description={"When will the consumer receive the voucher?"}>
               <div>

                  <RadioGroup aria-label="voucherUsed" name="voucherUsed" value={voucherStrategy}
                              onChange={(e) => setValue('voucherStrategy', e.target.value as EnumBrandRuleVoucherStrategy)}>
                     <FormControlLabel value="INSTANT" control={<Radio color={"primary"}/>}
                                       label="Instantly - The customer will receive the discount upon registrering the tradein"/>
                     <FormControlLabel value="RECEIVED" control={<Radio color={"primary"}/>}
                                       label="Received - When the returned items are received by the logistics partner, the voucher will be activated"/>
                     <FormControlLabel value="HANDIN" control={<Radio color={"primary"}/>}
                                       label="Handin - The customer receives the voucher when the package is registered at the postoffice."/>
                  </RadioGroup>
               </div>
            </SettingsGroup>
         </div>
      </form>
   </FormProvider>
}

const useSettingStyles = makeStyles({
   root: {
      marginBottom: '30px'
   },
   panel: {},
   title: {
      marginTop: '10px',
      marginBottom: '10px'
   }
});

const VoucherPricingEditor: FC<{ shopId: string }> = ({shopId}) => {
   const methods = useFormContext<DtcEditBrandRuleFragment>();
   const {control} = methods;
   const {fields, append, remove} = useFieldArray({
      control,
      name: 'voucherAmounts'
   })

   const [tags, setTags] = useState<string[] | undefined>();
   const {data} = useVoucherEditorTagsQuery({
      variables: {
         shopId: shopId
      }
   })

   useEffect(() => {
      if (data) {
         setTags(data.ShopById?.productTags || [])
      }
   }, [data])

   const [advanced, setAdvanced] = React.useState<boolean>(fields.length > 0);

   const options = (tags || []).map(tag => {
      return {
         id: tag,
         name: tag
      }
   })

   return <div>
      {!advanced && <Link style={{cursor: 'pointer'}} onClick={() => {
         append({
            value: 30,
            active: true,
            tag: ''
         } as BrandRuleVoucherAmounts)
         setAdvanced(true)
      }}>Advanced...</Link>}
      {advanced && <>
         {(fields || []).map((field, index) => {
            return <div key={field.id}
                        style={{display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'center'}}>
               <FormInputAutocomplete label={"Product tag"} name={`voucherAmounts[${index}].tag`} options={options}/>
               <FormInputNumber name={`voucherAmounts.${index}.value`} label={"Voucher value (0-100%)"}
                                placeholder={"%"}
                                InputProps={{
                                   endAdornment: <Button size={"small"} style={{minWidth: '30px'}}
                                                         onClick={() => remove(index)}><DeleteIcon/></Button>
                                }}
               />
            </div>
         })}
         <Button variant={"outlined"} onClick={() => append({
            value: 30,
            active: true,
            tag: ''
         } as BrandRuleVoucherAmounts)}>Add</Button>
      </>}
   </div>
}
